import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    key_amount: 0
  },
  mutations: {
    setKeyAmount(state, amount) {
      window.localStorage.setItem("key_amount", amount);
      state.key_amount = amount;
    },
  },
  getters: {
    
  }
})