<template>
    <v-container style="padding: 0px; ">
        <v-row no-gutters>
            <v-col style="padding:3px;">
                <v-responsive :aspect-ratio="16/9">
                    <v-img :src="require('@/assets/images/dudu.jpg')"></v-img>
                </v-responsive>
            </v-col>
        </v-row>
        <span class="text-h6">HOT</span>

        <vue-horizontal>
            <section v-for="(cont, i) in hot_list" :key="i" style="padding:3px; width: 33%">
                <v-card width="100%" outlined :href="cont.link" hover :ripple="false" class="ㅇ" style="border-width: 1px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
                    <v-row no-gutters>
                        <v-col>
                            <v-img :src="cont.image" aspect-ratio="0.8" class="my-1">
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-alert dense outlined color="white" class="mb-0 px-1">
                        <span style="font-size: 0.7rem; color: black">{{ cont.title1 }}</span><br>
                        <span style="font-size: 0.9rem; color: black">{{ cont.title2 }}</span>
                        <template v-slot:append>
                            <v-icon color="black">mdi-forward</v-icon>
                        </template>
                        </v-alert>
                </v-card>
            </section>
        </vue-horizontal>
        <!-- <v-row no-gutters>
            <v-col cols="4" v-for="(cont, i) in hot_list" :key="i" style="padding:3px;">
                <v-card outlined :href="cont.link" hover :ripple="false" class="ㅇ" style="border-width: 1px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
                    <v-row no-gutters>
                        <v-col>
                            <v-img :src="cont.image" aspect-ratio="0.8" class="my-1">
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-alert dense outlined color="white" class="mb-0 px-1">
                        <span style="font-size: 0.7rem; color: black">{{ cont.title1 }}</span><br>
                        <span style="font-size: 0.9rem; color: black">{{ cont.title2 }}</span>
                        <template v-slot:append>
                            <v-icon color="black">mdi-forward</v-icon>
                        </template>
                        </v-alert>
                </v-card>
            </v-col>
        </v-row> -->
        <span class="text-h6">NEW</span>
        <v-row no-gutters>
            <v-col cols="6" v-for="(cont, i) in quiz_list" :key="i" style="padding:3px;">
                <v-card outlined :href="cont.link" hover :ripple="false" style="border-width: 1px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
                    <v-row no-gutters>
                        <v-col>
                            <v-img :src="cont.image" aspect-ratio="0.8" class="my-1">
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-alert dense outlined color="white" class="mb-0 px-1">
                        <span style="font-size: 0.7rem; color: black">{{ cont.title1 }}</span><br>
                        <span style="font-size: 0.9rem; color: black">{{ cont.title2 }}</span>
                        <template v-slot:append>
                            <v-icon color="black">mdi-forward</v-icon>
                        </template>
                        </v-alert>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ServerAPI from '@/api/server.js'

export default {
    name: 'TestPage',

    data: () => ({
        banner: null,
        hot_list: [],
        quiz_list: []
    }),
    mounted: function() {
        this.refreshPage();
    },
    methods: {
        refreshPage: function() {
            ServerAPI.getQuiz('asdf').then((response) => {
                this.hot_list = response.hot_list;
                this.quiz_list = response.quiz_list;
                console.log(this.quiz_list);
        })
        }
    }
}
</script>
