<template>
    <div>
        <div style="position: absolute; top: 0; left:0; width: 100%; height: 40px" class="d-flex justify-center align-center">
          <div style="height: 30;">
            <a href="https://www.dudu.im/"><v-img :src="require('@/assets/images/play_dudu_logo.png')" height="30" contain alt="Play DuDu"></v-img></a>
          </div>
        </div>
  
        <v-system-bar color="mainColor" dark height="40">
          <div class="d-flex justify-space-between align-center" style="width: 100%; height: 100%">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            
            <v-card rounded color="white" elevation="1" height="30" width="75"  v-if="isSignedIn">
              <div class="d-flex justify-space-between align-center px-2" style="width: 100%; height: 100%;">
                <div>
                  <v-img :src="require('@/assets/images/key.png')" width="20" contain></v-img>
                </div>
                <span style="color: black;">{{ keyAmount }}</span>
              </div>
            </v-card>
  
            <div class="d-flex align-center" style="height: 40" v-if="!isSignedIn">
              <v-btn text><img id="kakaoLoginBtn" :src="require('@/assets/images/kakao_login_small.png')" @click="signIn" height="30" contain/> </v-btn>
            </div>
          </div>
        </v-system-bar>
    </div>
  </template>
  
  <script>
  import KakaoAPI from '@/api/kakao';
  
  export default {
    name: 'TopBar',
    computed: {
      keyAmount: function() {
        return this.$store.state.key_amount;
      },
      isSignedIn: function() { 
        return KakaoAPI.isSignedIn();
      },
    },
    methods: {
      signIn: function() {
        KakaoAPI.signIn();
      },
    }
  };
  </script>