<template>
    <v-container style="padding: 0px;">
        <v-row no-gutters>
            <v-col style="padding:3px;">
                <v-responsive :aspect-ratio="16/9">
                    <v-img :src="require('@/assets/images/dudu.jpg')"></v-img>
                </v-responsive>
            </v-col>
        </v-row>
        <span class="text-h6">HOT</span>

        <vue-horizontal>
            <section v-for="(cont, i) in hot_list" :key="i" style="padding:3px; width: 33%">
                <v-card width="100%" outlined :href="cont.link" hover :ripple="false" class="ㅇ" style="border-width: 1px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
                    <v-row no-gutters>
                        <v-col>
                            <v-img :src="cont.image" aspect-ratio="0.8" class="my-1">
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-alert dense outlined color="white" class="mb-0 px-1">
                        <span style="font-size: 0.7rem; color: black">{{ cont.title1 }}</span><br>
                        <span style="font-size: 0.9rem; color: black">{{ cont.title2 }}</span>
                        <template v-slot:append>
                            <v-icon color="black">mdi-forward</v-icon>
                        </template>
                        </v-alert>
                </v-card>
            </section>
        </vue-horizontal>

        <span class="text-h6">NEW</span>
        <v-row no-gutters v-for="(cont, i) in quiz_list" :key="i" style="padding:3px;">
            <v-col>
                <v-card outlined :href="cont.link" hover :ripple="false" style="border-width: 1px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
                    <div class="d-flex">
                        <div style="width: 50%">
                            <v-row no-gutters>
                                <v-col>
                                    <v-img :src="cont.image" aspect-ratio="0.8" class="my-1">
                                    </v-img>
                                </v-col>
                            </v-row>                        
                            <v-alert dense outlined color="white" class="mb-0 px-1">
                                <span style="font-size: 0.7rem; color: black">{{ cont.title1 }}</span><br>
                                <span style="font-size: 0.9rem; color: black">{{ cont.title2 }}</span>
                                <template v-slot:append>
                                    <v-icon color="black">mdi-forward</v-icon>
                                </template>
                            </v-alert>
                        </div>
                        
                        <v-divider vertical class="mx-1" color="#F08822"></v-divider>
                        
                        <div style="width: 50%">
                            <v-row no-gutters>
                                <v-col style="text-align: center; font-size: 1.2rem;">
                                    랭킹
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-for="(ranker, j) in cont.shortRanking" :key="j" style="padding:3px;">
                                {{ ranker }}
                            </v-row>
                            <div style="position: absolute; bottom: 0; left: 0; width: 100%;" class="d-flex justify-end">
                                <v-btn text x-large color="mainColor" @click.prevent="openRankingDetail(cont.ranker_info)">더보기</v-btn>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="rankingDetail.show" scrollable max-width="500px">
            <v-card>
                <div class="d-flex justify-center text-h4 mt-4">랭킹</div>
                <div style="position: absolute; top: 0; left: 0; width: 100%;" class="d-flex justify-end">
                    <v-btn text x-large color="black" class="mt-2" @click.prevent="rankingDetail.show = false">X</v-btn>
                </div>
                <v-simple-table fixed-header height="600px" style="padding: 10px;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">순위</th>
                                <th class="text-left">닉네임</th>
                                <th class="text-left">소속</th>
                            </tr>
    
                        </thead>
                        <tbody>
                            <tr v-for="item in rankingDetail.ranking_info" :key="`${item.rank}등`">
                                <td>{{ item.rank }}등</td>
                                <td>{{ item.nick }}</td>
                                <td>{{ item.party }}</td>
                            </tr>
                        </tbody>
    </template>
  </v-simple-table>

            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ServerAPI from '@/api/server.js'

export default {
    name: 'RankQuizPage',

    data: () => ({
        banner: null,
        hot_list: [],
        quiz_list: [],
        rankingDetail: {
            show: false,
            ranking_info: []
        }
    }),
    mounted: function() {
        this.refreshPage();
    },
    methods: {
        refreshPage: function() {
            ServerAPI.getRankingQuiz().then((response) => {
                this.hot_list = response.hot_list;
                this.quiz_list = response.quiz_list;
                for(let i in this.quiz_list) {
                    let shortRanking = [];
                    let ranker_info = this.quiz_list[i].ranker_info;
                    for(let j in ranker_info) {
                        shortRanking.push(`${ranker_info[j].rank}등 (${ranker_info[j].score}점) : ${ranker_info[j].nick}`);
                    }
                    this.quiz_list[i].shortRanking = shortRanking;
                }
                console.log(this.quiz_list);
        })
        },
        openRankingDetail: function(ranking_info) {
            this.rankingDetail.ranking_info = ranking_info;
            this.rankingDetail.show = true;
        }
    }
}
</script>
