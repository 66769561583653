import axios from 'axios'
import store from '@/store'

export default {
    name: 'ServerAPI',
    //serverUrl: "http://127.0.0.1:8000",
    serverUrl: "https://api.dudu.im",
    getApiUrl: function(path) {
        return `${this.serverUrl}/${path}`;
    },
    handleResponse: function(respPromise) {
        let doHandle = async function(res) {
            let resp = await respPromise;
            if(resp.status == 200) {
                if(Object.prototype.hasOwnProperty.call(resp.data, "user_uuid")) {
                    window.localStorage.setItem("user_uuid", resp.data.user_uuid);
                }

                if(Object.prototype.hasOwnProperty.call(resp.data, "key_amount"))
                    store.commit("setKeyAmount", resp.data.key_amount);
                res(resp.data);
            }
            else {
                res({"result": -1});
            }
        };
 
        return new Promise(doHandle);
    },
    doGetRequest: function(path) {
        let params = [];
        if(Object.prototype.hasOwnProperty.call(window.localStorage, "user_uuid"))
            params.push(`user_uuid=${window.localStorage.getItem("user_uuid")}`)
        return this.handleResponse(axios.get(this.getApiUrl(path) + `?${params.join("&")}`));
    },
    getQuiz: function() {
        return this.doGetRequest('quiz');
    },
    getRankingQuiz: function() {
        return this.doGetRequest('ranking/quiz');
    }
}