<template>
  <v-card height="100%" elevation="0" class="mx-3">
    <v-tabs color="mainColor" v-model="tab" background-color="transparent" grow>
        <v-tab href="#tab-test" :ripple="false" @change="tabChanged"><span style="color: black">두두 테스트</span></v-tab>
        <v-tab href="#tab-quiz" :ripple="false" @change="tabChanged"><span style="color: black">랭킹 퀴즈</span></v-tab>
    </v-tabs>
<!-- 
    <v-card class="mt-2" outlined>
        <v-responsive :aspect-ratio="16/9">
            <div class="d-flex align-center justify-center" style="width: 100%; height: 100%;">
            메인 배너
            </div>
        </v-responsive>
    </v-card> -->

    <v-tabs-items v-model="tab" touchless>
        <v-tab-item value='tab-test'>
            <TestPage/>
        </v-tab-item>

        <v-tab-item value='tab-quiz'  style="height: 100%" touchless>
            <RankQuizPage/>
        </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import TestPage from './TestPage.vue';
import RankQuizPage from './RankQuizPage.vue';
//import KakaoAPI from '@/api/kakao.js'

export default {
    name: 'MainPage',
    data: () => ({
        tab: null,
        contents: [],
        pageLoading: true,
    }),
    components: {
        TestPage,
        RankQuizPage
    },
    methods: {
        tabChanged: function() {
            //KakaoAPI.signIn();
        }
    },
    mounted: function() {
        this.tabChanged();
    }
    }
</script>
