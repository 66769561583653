<template>

  <v-app>
    <v-main>
      <TopBar></TopBar>
      <v-container style="max-width: 550px; padding: 0px;">
        <v-row no-gutters>
          <v-col>
            <MainPage/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style >
#app {
  display: flex;
  justify-content: center;
  align-items: center;
} 


</style>


<script>
import MainPage from './views/MainPage';
import KakaoAPI from './api/kakao';
import TopBar from './components/TopBar'

export default {
  name: 'App',

  components: {
    MainPage,
    TopBar
  },

  data: () => ({
    
  }),
  computed: {
    keyAmount: function() {
      return this.$store.state.key_amount;
    },
    isSignedIn: function() { 
      return KakaoAPI.isSignedIn();
    },
  },
  mounted() {
    
  },
  methods: {
    signIn: function() {
      KakaoAPI.signIn();
    },
  }
};
</script>
