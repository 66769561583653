export default {
    name: "KakaoAPI",
    isSignedIn: function() {
        return Object.prototype.hasOwnProperty.call(window.localStorage, "user_uuid");
    },
    signOut: function() {
        if(this.isSignedIn())
            window.localStorage.removeItem("user_uuid");
    },
    signIn: function() {
        if(this.isSignedIn())
            return;
        window.localStorage.setItem("returnUri", "https://www.dudu.im/")
        console.log(window.localStorage.getItem("returnUri"));
        window.Kakao.Auth.authorize({
            redirectUri: 'https://www.dudu.im/kakao/redirect',
        });  
    }
}